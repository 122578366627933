import styled from 'styled-components';

// components
import { ChainSelect } from './ChainSelect';

// services
import { chainToChainDetails } from '../services/wallet';

// hooks
import { useWallet } from '../providers/WalletProvider';

// utils
import { color } from '../utils/theme';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ViewHistory = styled.div`
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
  background: ${color.linearRed};
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;

  &:hover {
    opacity: 0.5;
  }
`;

export const History = () => {
  const { chain, setChain, address } = useWallet();

  const { historyExplorerUrl } = chainToChainDetails[chain];

  const onChainSelected = (newChain) => {
    setChain(newChain);
  };

  const onViewHistoryClick = () => {
    window.open(`${historyExplorerUrl}/${address}`, '_blank').focus();
  };

  return (
    <Wrapper>
      <ChainSelect value={chain} onChange={onChainSelected} />
      {!!historyExplorerUrl && <ViewHistory onClick={onViewHistoryClick}>View history on explorer</ViewHistory>}
      {!historyExplorerUrl && <span>No history available yet</span>}
    </Wrapper>
  )
};
