import { createGlobalStyle } from 'styled-components';
import Modal from 'react-modal';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';

// components
import { Wallet } from '../components/Wallet'

// utils
import { fontFamily, fontWeight } from '../utils/theme'

// providers
import { WalletProvider } from '../providers/WalletProvider'
import { WalletConnectProvider } from '../providers/WalletConnectProvider'


Modal.setAppElement('#root');

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
  }
  
  body, input {
    font-family: "${fontFamily.default}", sans;
    font-weight: ${fontWeight.regular};
  }
  
  b, strong {
    font-weight: ${fontWeight.bold};
  }

  &:focus {
    outline: none;
  }
`;

const App = () => (
  <>
    <GlobalStyle />
    <Router>
    <Switch>
      <Route exact path={['', '/:referralCode']}>
        <WalletProvider>
          <WalletConnectProvider>
            <Wallet />
          </WalletConnectProvider>
        </WalletProvider>
      </Route>
    </Switch>
    </Router>
  </>
);

export default App;
