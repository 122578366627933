import styled from 'styled-components';
import { useState } from 'react';
import { ethers } from 'ethers'

// components
import { Modal } from './Modal'

// utils
import { color } from '../utils/theme'
import { formatNumber, isCaseInsensitiveMatch } from '../utils/common'

// hooks
import { useWallet } from '../providers/WalletProvider'


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;

  ${({ $clickable }) => $clickable && `
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  `};
`;

const AssetIcon = styled.img`
  height: 30px;
  ${({ $marginRight }) => $marginRight && `margin-right: ${$marginRight}px;`};
`;

const AssetSelectEntry = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid ${color.orange};
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`;

const AssetBalance = styled.div`
  flex: 1;
  text-align: right;
  font-size: 14px;
`;

export const AssetSelect = ({ onChange, value: currentAsset }) => {
  const { assets, balances } = useWallet();
  const [assetSelectVisible, setAssetSelectVisible] = useState(false);

  const onAssetSelect = (newAsset) => {
    onChange(newAsset);
    setAssetSelectVisible(false);
  }

  const assetsWithBalances = assets?.map((asset) => {
    const assetBalance = balances.find((balance) => isCaseInsensitiveMatch(
      asset.address,
      balance.assetAddress,
    ));

    const balance = assetBalance
      ? ethers.utils.formatUnits(assetBalance.balance, asset.decimals)
      : 0;

    return { ...asset, balance };
  });

  const assetsWithPositiveBalances = assetsWithBalances?.filter(({ balance }) => balance > 0);

  const showAssetSelect = assetsWithPositiveBalances?.length > 1;

  return (
    <Wrapper $clickable={showAssetSelect}>
      <AssetIcon src={currentAsset.iconUrl} onClick={() => assetsWithPositiveBalances?.length && setAssetSelectVisible(true)} />
      {showAssetSelect && (
        <Modal
          isVisible={assetSelectVisible}
          onClose={() => setAssetSelectVisible(false)}
        >
          {assetsWithPositiveBalances.map((asset) => (
            <AssetSelectEntry key={asset.address} onClick={() => onAssetSelect(asset)}>
              <AssetIcon $marginRight={15} src={asset.iconUrl} />
              {asset.name}
              <AssetBalance>Balance: {formatNumber(asset.balance)} {asset.symbol}</AssetBalance>
            </AssetSelectEntry>
          ))}
        </Modal>
      )}
    </Wrapper>
  )
};
