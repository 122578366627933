import { utils } from 'ethers';
import { chainToNativeAsset } from '../services/wallet'

export const parseMessageSignParamsFromCallRequest = (callRequest) => {
  const { method, params } = callRequest;

  let callRequestParams = [...params];
  if (method === 'personal_sign') {
    // different param order on PERSONAL_SIGN
    callRequestParams = callRequestParams.reverse();
  }

  const [address, message] = callRequestParams;

  let displayMessage;
  if (method === 'personal_sign') {
    try {
      displayMessage = utils.toUtf8String(message);
    } catch (e) {
      ([, displayMessage] = callRequestParams);
    }
  } else if (method.startsWith('eth_signTypedData')) {
    displayMessage = 'Typed data message';
  } else {
    ([, displayMessage] = callRequestParams);
  }

  return { address, message, displayMessage };
};

export const parseCallRequest = async (chain, callRequest) => {
  const { params: requestParams } = callRequest;
  let displayParams = [{ label: 'Method', value: callRequest.method }];
  let transaction;
  let message;

  switch (callRequest.method) {
    case 'eth_sendTransaction':
    case 'eth_signTransaction':
      let { symbol } = chainToNativeAsset[chain];

      transaction = {
        to: requestParams[0].to,
        value: requestParams[0].value,
        data: requestParams[0].data,
      };

      displayParams = [
        ...displayParams,
        { label: 'From', value: requestParams[0].from },
        { label: 'To', value: requestParams[0].to },
        {
          label: 'Value',
          value: requestParams[0].value ? `${utils.formatUnits(requestParams[0].value)} ${symbol}` : '',
        },
        {
          label: 'Data',
          value: requestParams[0].data?.length > 100
            ? `${requestParams[0].data.slice(0, 50)}...${requestParams[0].data.slice(requestParams[0].data.length - 50, requestParams[0].data.length)}`
            : requestParams[0].data,
        },
      ];
      break;

    default:
      const parsed = parseMessageSignParamsFromCallRequest(callRequest);
      const { address, displayMessage } = parsed;
      ({ message } = parsed);
      displayParams = [
        ...displayParams,
        { label: 'Address', value: address },
        { label: 'Message', value: displayMessage },
      ];
      break;
  }

  return { displayParams, transaction, message };
}
