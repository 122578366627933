import React, { useCallback, useEffect, useMemo, useState } from 'react'

// services
import {
  chainToChainDetails,
  connectWallet,
  getBalances,
  getSupportedAssets,
  getSupportedChains,
  getWalletAddress
} from '../services/wallet'
import {
  estimateEtherspotTransactions,
  getSubmittedEtherspotBatch,
  sendEtherspotTransactions
} from '../services/etherspot'

export const WalletContext = React.createContext(null);

export const WalletProvider = ({ children }) => {
  const supportedChains = getSupportedChains();

  const [connectionError, setConnectionError] = useState(null);
  const [isConnecting, setIsConnecting] = useState(true);
  const [address, setAddress] = useState(null);
  const [chain, setChain] = useState(supportedChains[0]);
  const [assets, setAssets] = useState([]);
  const [balancesPerChain, setBalancesPerChain] = useState({});
  const [isFetchingAssetsAndBalances, setIsFetchingAssetsAndBalances] = useState(false);

  const [isEstimating, setIsEstimating] = useState(false);
  const [isSendingTransaction, setIsSendingTransaction] = useState(false);
  const [transactionsErrorMessage, setTransactionsErrorMessage] = useState(null);
  const [transactionsEstimate, setTransactionsEstimate] = useState(null);
  const [sentTransactions, setSentTransactions] = useState(null);

  const connect = useCallback(async (isInitial = false) => {
    if (isConnecting && !isInitial) return;
    setIsConnecting(true);
    setConnectionError(null);

    try {
      // connect to all supported
      for (const supportedChain of supportedChains) {
        await connectWallet(supportedChain, isInitial);
      }

      // details from active
      const walletAddress = getWalletAddress(chain);
      setAddress(walletAddress);
    } catch (error) {
      setConnectionError(error?.message ?? 'Unable to connect, please try again.');
    }

    setIsConnecting(false);
  }, [isConnecting, chain]); // eslint-disable-line

  // initial session restore
  useEffect(() => {
    connect(true);
  }, []); // eslint-disable-line

  const fetchAssetsAndBalances = useCallback(async () => {
    if (!address) return;
    setIsFetchingAssetsAndBalances(true);
    const fetchedAssets = await getSupportedAssets(chain);
    setAssets(fetchedAssets);

    const fetchedBalances = await getBalances(chain, fetchedAssets);
    setBalancesPerChain({ [chain]: fetchedBalances, ...balancesPerChain });
    setIsFetchingAssetsAndBalances(false);
  }, [address, chain]); // eslint-disable-line

  const estimateTransactions = async (chainToEstimate, transactions) => {
    setTransactionsErrorMessage(null);
    setTransactionsEstimate(null);
    setIsEstimating(true);

    try {
      const estimate = await estimateEtherspotTransactions(chainToEstimate, transactions);
      setTransactionsEstimate(estimate);
    } catch (e) {
      setTransactionsErrorMessage(e?.message ?? 'Failed to estimate');
    }

    setIsEstimating(false);
  };

  const sendTransactions = useCallback(async (chainToSend, transactions, waitForHash) => {
    if (isSendingTransaction) return;

    setTransactionsEstimate(null);
    setSentTransactions(null);
    setTransactionsErrorMessage(null);
    setIsSendingTransaction(true);

    let sent;
    try {
      sent = await sendEtherspotTransactions(chainToSend, transactions, waitForHash);
      setSentTransactions(sent);
      setIsSendingTransaction(false);
    } catch (e) {
      console.log(e);
      setTransactionsErrorMessage(e?.message ?? 'Failed to send');
    }

    setIsSendingTransaction(false);
    return sent;
  }, [isSendingTransaction]);

  const resetSentTransactions = useCallback(() => setSentTransactions(null), []);

  const viewTransaction = useCallback(async ({ hash, batchHash }) => {
    const { transactionExplorerUrl } = chainToChainDetails[chain];
    if (!transactionExplorerUrl) {
      alert('No blockachain explorer available!');
      return;
    }

    if (!hash && batchHash) {
      hash = await getSubmittedEtherspotBatch(chain, batchHash);
    }

    if (!hash) {
      alert('Transaction hash not yet available!');
      return;
    }

    window.open(`${transactionExplorerUrl}/${hash}`, '_blank').focus();
  }, [chain]);

  // initial session restore
  useEffect(() => {
    fetchAssetsAndBalances();
  }, [fetchAssetsAndBalances]);

  const wallet = useMemo(() => ({
    isConnecting,
    isConnected: !!address,
    connectionError,
    address,
    connect,
    assets,
    balances: balancesPerChain?.[chain] ?? [],
    chain,
    setChain,
    transactionsEstimate,
    transactionsErrorMessage,
    isEstimating,
    estimateTransactions,
    sendTransactions,
    isSendingTransaction,
    sentTransactions,
    resetSentTransactions,
    viewTransaction,
    isFetchingAssetsAndBalances,
  }), [
    isConnecting,
    connectionError,
    address,
    connect,
    assets,
    balancesPerChain,
    chain,
    setChain,
    transactionsEstimate,
    transactionsErrorMessage,
    isEstimating,
    sendTransactions,
    isSendingTransaction,
    sentTransactions,
    resetSentTransactions,
    viewTransaction,
    isFetchingAssetsAndBalances,
  ]);

  return (
    <WalletContext.Provider value={{ wallet }}>
      {children}
    </WalletContext.Provider>
  )
};

export const useWallet = () => React.useContext(WalletContext)?.wallet ?? {}
