import ReactModal from 'react-modal';
import styled from 'styled-components';
import { FiX } from "react-icons/fi";

// utils
import { color } from '../utils/theme'


const ModalWrapper = styled.div`
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
`;

const ModalContentWrapper = styled.div`
  padding: 15px;
  border: 3px solid ${color.orange};
  border-radius: 15px;
  width: 400px;
  position: relative;
`;

const CloseButton = styled(FiX)`
  position: absolute;
  top: -50px;
  right: 0;
  cursor: pointer;
  padding: 5px;

  &:hover {
    opacity: 0.3;
  }
`;

export const Modal = ({ children: modalContent, isVisible, onClose }) => (
  <ReactModal
    isOpen={isVisible}
    onRequestClose={onClose}
    contentElement={(props, children) => (
      <ModalWrapper {...props} style={{}}>
        <ModalContentWrapper>
          <CloseButton size={30} onClick={onClose} />
          {children}
        </ModalContentWrapper>
      </ModalWrapper>
    )}
  >
    {modalContent}
  </ReactModal>
);
