import { ethers } from 'ethers';

// abi
import erc20Abi from '../abi/erc20.json'

// services
import { chainToNativeAsset } from '../services/wallet'


export const isCaseInsensitiveMatch = (a, b) => {
  if (a === b) return true;
  if (!a || !b) return false;
  return a.toLowerCase() === b.toLowerCase();
};

export const pause = (multiplier) => new Promise(resolve => setTimeout(resolve, (multiplier || 1) * 1000));

export const formatNumber = (value) => new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(value);

const supportedDomains = [
  'eth',
  'crypto',
  'zil',
];

export const isValidEnsName = (input) => {
  if (!input || !input.toString().includes('.')) return false;

  const domain = input.split('.').pop().toLowerCase();

  return supportedDomains.includes(domain);
};

export const isValidAddress = (input) => {
  if (!input) return false;

  try {
    ethers.utils.getAddress(input);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidAddressOrEnsName = (input) => isValidEnsName(input) || isValidAddress(input);

export const encodeContractMethod = (contractAbi, method, params) => {
  const contractInterface = new ethers.utils.Interface(contractAbi);
  return contractInterface.encodeFunctionData(method, params);
};

export const getErc20TransferData = (to, value) => encodeContractMethod(
  erc20Abi,
  'transfer',
  [to, value],
);

export const buildTransactions = (chain, asset, receiver, amount) => {
  let value = ethers.utils.parseUnits(amount, asset.decimals);

  let data;
  let to = receiver;
  if (asset.address !== chainToNativeAsset[chain].address) {
    to = asset.address;
    data = getErc20TransferData(receiver, value);
    value = undefined;
  }

  return [{ to, value, data }];
}
