import {
  EnvNames,
  NetworkNames,
  Sdk,
  Web3WalletProvider,
  SessionStorage,
  AccountStates,
  GatewayTransactionStates,
  addressesEqual,
  NotificationTypes,
  AccountMemberStates,
} from 'etherspot';
import Torus from '@toruslabs/torus-embed';
import { BigNumber } from 'ethers';
import { isValidAddress, toChecksumAddress } from 'ethereumjs-util';
import { map } from 'rxjs/operators';

// constants
import { CHAIN } from '../constants/chainConstants';
import {
  ADDRESS_ZERO,
  COLLAB_LOCKER_CONTRACT_ADDRESS_AURORA_TESTNET,
  COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET,
  COLLAB_NFT_CONTRACT_ADDRESS_POLYGON_MUMBAI_TESTNET,
  COLLAB_BOT_ADDRESS,
} from '../constants/assetConstants'

// utils
import { isCaseInsensitiveMatch } from '../utils/common';

// services
import { getItem, setItem } from './storage';
import { chainToNativeAsset } from './wallet'


class LocalSessionStorage extends SessionStorage {
  prefix;

  constructor (prefix) {
    super();
    this.prefix = prefix;
  }

  setSession = async (walletAddress, session) => {
    if (walletAddress) {
      setItem(`@${this.prefix ?? ''}etherspotSession:${walletAddress}`, JSON.stringify(session))
      setItem(`@${this.prefix ?? ''}etherspotSessionExists`, true);
    }
  }

  getSession = (walletAddress) => {
    let result = null;

    try {
      const raw = getItem(`@${this.prefix ?? ''}etherspotSession:${walletAddress}`)
      result = raw ? JSON.parse(raw) : null
    } catch (err) {
      //
    }

    return result
  }

  resetSession = (walletAddress) => {
    setItem(`@${this.prefix ?? ''}etherspotSession:${walletAddress}`, '');
    setItem(`@${this.prefix ?? ''}etherspotSessionExists`, '');
  }
}

const sessionStorageInstance = new LocalSessionStorage();
const testnetSessionStorageInstance = new LocalSessionStorage('testnet-');

let etherspotSdkInstances = {};

const chainToNetworkName = {
  [CHAIN.POLYGON]: NetworkNames.Matic,
  [CHAIN.ETHEREUM_MAINNET]: NetworkNames.Mainnet,
  [CHAIN.ETHEREUM_KOVAN]: NetworkNames.Kovan,
  [CHAIN.AURORA]: NetworkNames.Aurora,
  [CHAIN.AURORA_TESTNET]: NetworkNames.AuroraTest,
  [CHAIN.POLYGON_MUMBAI]: NetworkNames.Mumbai,
};

const testnetNetworks = [
  CHAIN.ETHEREUM_KOVAN,
  CHAIN.AURORA_TESTNET,
  CHAIN.POLYGON_MUMBAI,
];

const getEnvForChain = (chain) => {
  if (testnetNetworks.includes(chain)) return EnvNames.TestNets;

  return EnvNames.MainNets;
};

let torusWalletProvider;
let torusWalletAddress;

const createTorusWalletProvider = async () => {
  if (torusWalletProvider) return torusWalletProvider;

  const torus = new Torus();
  await torus.init({ showTorusButton: false });
  ([torusWalletAddress] = await torus.login());

  torusWalletProvider = torus.provider;
}

export const getTorusWalletProvider = () => torusWalletProvider;

export const getTorusWalletAddress = () => torusWalletAddress;

export const createEtherspotInstance = async (chain) => {
  await createTorusWalletProvider();
  if (!torusWalletProvider) {
    throw new Error('Failed to get wallet provider, please try again.');
  }

  const connectedTorusProvider = await Web3WalletProvider.connect(torusWalletProvider);
  if (!connectedTorusProvider) {
    throw new Error('Failed to connect to wallet provider, please try again.');
  }


  const networkName = chainToNetworkName[chain];
  if (!networkName) {
    throw new Error('Unsupported network.');
  }

  const env = getEnvForChain(chain);
  if (!env) {
    throw new Error('Unsupported network.');
  }

  const sessionStorage = env === EnvNames.MainNets
    ? sessionStorageInstance
    : testnetSessionStorageInstance;

  etherspotSdkInstances[chain] = new Sdk(connectedTorusProvider, {
    sessionStorage,
    env,
    networkName,
    omitWalletProviderNetworkCheck: true,
    projectKey: '2bae9e8ec5f246f1b80472b9f4e1a9b3',
  });

  return etherspotSdkInstances[chain].computeContractAccount();
}

export const etherspotSessionExists = () => {
  return !!getItem('@etherspotSessionExists');
}

export const getEtherspotChainInstance = (chain) => etherspotSdkInstances[chain];

export const getEtherspotFirstInstance = () => etherspotSdkInstances[Object.keys(etherspotSdkInstances)[0]];

export const getEtherspotAccountAddress = (chain) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return null;

  return etherspotInstance.state.account.address;
}

export const getEtherspotAssets = async (chain) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return [];

  let tokens = [];
  try {
    tokens = await etherspotInstance.getTokenListTokens({ name: 'PillarTokens' });

    tokens = tokens.map(({ logoURI, ...token }) => ({ ...token, iconUrl: logoURI }));

    const nativeAsset = chainToNativeAsset[chain];
    if (!tokens.some(({ symbol }) => isCaseInsensitiveMatch(symbol, nativeAsset.symbol))) {
      tokens = [nativeAsset, ...tokens];
    }
  } catch (err) {
    //
  }

  return tokens;
}

export const getEtherspotAsset = async (chain, assetAddress) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return [];

  let tokens = [];
  try {
    tokens = await etherspotInstance.getTokenListTokens({ name: 'PillarTokens' });

    tokens = tokens.map(({ logoURI, ...token }) => ({ ...token, iconUrl: logoURI }));

    const nativeAsset = chainToNativeAsset[chain];
    if (!tokens.some(({ symbol }) => isCaseInsensitiveMatch(symbol, nativeAsset.symbol))) {
      tokens = [nativeAsset, ...tokens];
    }
  } catch (err) {
    //
  }

  return tokens.find(({ address }) => addressesEqual(address, assetAddress));
}

export const getEtherspotBalances = async (chain, assets) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return [];

  const nativeAsset = chainToNativeAsset[chain];
  const tokens = assets?.filter(({ symbol }) => !isCaseInsensitiveMatch(symbol, nativeAsset.symbol));
  const tokenAddresses = tokens?.map(({ address }) => address);

  let balances = [];
  try {
    const { items } = await etherspotInstance.getAccountBalances({ tokens: tokenAddresses });
    balances = items
      .map(({ token, balance }) => {
        const assetAddress = token ?? ADDRESS_ZERO; // zero address for native asset of token null
        return { assetAddress, balance };
      })
      .filter(({ balance }) => balance && balance.gt(0));
  } catch (err) {
    //
  }

  return balances;
}

export const resolveEns = (nameOrHashOrAddress) => {
  const etherspotInstance = getEtherspotFirstInstance();
  if (!etherspotInstance) return null;

  const nameOrHashOrChecksumAddress = nameOrHashOrAddress.startsWith('0x') && isValidAddress(nameOrHashOrAddress)
    ? toChecksumAddress(nameOrHashOrAddress)
    : nameOrHashOrAddress;

  try {
    return etherspotInstance.getENSNode({ nameOrHashOrAddress: nameOrHashOrChecksumAddress })
  } catch (err) {
    console.log('err: ', err)
    //
  }

  return null;
}

export const estimateEtherspotTransactions = async (chain, transactions) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  etherspotInstance.clearGatewayBatch();

  const { account: etherspotAccount } = etherspotInstance.state;

  const excludeManualDeployment = [CHAIN.AURORA, CHAIN.AURORA_TESTNET].includes(chain);
  if (etherspotAccount.state === AccountStates.UnDeployed && !excludeManualDeployment) {
    /**
     * batchDeployAccount on back-end additionally checks if account is deployed
     * regardless of our state check and either skips or adds deployment transaction.
     */
    await etherspotInstance.batchDeployAccount();
  }

  for (const transaction of transactions) {
    await etherspotInstance.batchExecuteAccountTransaction(transaction);
  }

  const { estimation } = await etherspotInstance.estimateGatewayBatch();

  const { estimatedGas, estimatedGasPrice } = estimation;

  return BigNumber.from(estimatedGasPrice).mul(estimatedGas);
}

export const sendEtherspotTransactions = async (chain, transactions, waitForHash) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  await estimateEtherspotTransactions(chain, transactions);

  const { hash: batchHash } = await etherspotInstance.submitGatewayBatch();

  if (!waitForHash) return { batchHash };

  let temporaryBatchSubscription;

  return new Promise((resolve, reject) => {
    temporaryBatchSubscription = etherspotInstance.notifications$
      .pipe(map(async (notification) => {
        if (notification.type === NotificationTypes.GatewayBatchUpdated) {
          const submittedBatch = await etherspotInstance.getGatewaySubmittedBatch({ hash: batchHash });

          const failedStates = [
            GatewayTransactionStates.Canceling,
            GatewayTransactionStates.Canceled,
            GatewayTransactionStates.Reverted,
          ];

          let finishSubscription;
          if (submittedBatch?.transaction?.state && failedStates.includes(submittedBatch?.transaction?.state)) {
            finishSubscription = () => reject(submittedBatch.transaction.state);
          } else if (submittedBatch?.transaction?.hash) {
            finishSubscription = () => resolve({ batchHash, hash: submittedBatch.transaction.hash });
          }

          if (finishSubscription) {
            if (temporaryBatchSubscription) temporaryBatchSubscription.unsubscribe();
            finishSubscription();
          }
        }
      }))
      .subscribe();
  });
}

export const signEtherspotTransaction = async (chain, transaction) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  return etherspotInstance.encodeExecuteAccountTransaction(transaction);
}

export const getSubmittedEtherspotBatch = async (chain, batchHash) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return null;

  const { transaction  } = await etherspotInstance.getGatewaySubmittedBatch({ hash: batchHash });

  return transaction?.hash;
}

export const signMessageWithEtherspotProvider = async (message) => {
  const etherspotInstance = getEtherspotFirstInstance();
  if (!etherspotInstance) return null;

  let signed;
  try {
    signed = await etherspotInstance.signMessage({ message });
  } catch (err) {
    console.log('err: ', err)
    //
  }

  return signed;
}

const nftAbi = [
  'function approve(address approver, uint256 tokenId)', //
  'function transferFrom(address from, address to, uint256 tokenId)',
  'function burn(uint256 tokenId)',
  'function balanceOf(address owner) view returns (uint balance)',
  'function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint tokenId)',
];
/*
const lockerAbi = [
  'function migrateTokenToNear(address _token, uint256 _tokenId, string calldata _nearRecipientAccountId)',
];
*/

export const buildTestnetNftFromAuroraToPolygonMigrationTransactions = async (tokenId) => {
  const etherspotInstance = getEtherspotChainInstance(CHAIN.AURORA_TESTNET);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;

  const nftContract = etherspotInstance.registerContract('nftContract', nftAbi, COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET);
  // const lockerContract = etherspotInstance.registerContract('lockerContract', lockerAbi, COLLAB_LOCKER_CONTRACT_ADDRESS_AURORA_TESTNET);

  return [
    nftContract.encodeTransferFrom(accountAddress, COLLAB_LOCKER_CONTRACT_ADDRESS_AURORA_TESTNET, tokenId),
    // nftContract.encodeApprove(COLLAB_LOCKER_CONTRACT_ADDRESS_AURORA_TESTNET, tokenId),
    // lockerContract.encodeMigrateTokenToNear(COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET, tokenId, ''),
  ];
}

export const buildBurnNftTransaction = async (chain, tokenId) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const contractAddress =
    chain === CHAIN.AURORA_TESTNET
      ? COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET
      : COLLAB_NFT_CONTRACT_ADDRESS_POLYGON_MUMBAI_TESTNET;

  const nftContract = etherspotInstance.registerContract('nftContract', nftAbi, contractAddress);

  return nftContract.encodeBurn(tokenId);
}

export const getTokenId = async (chain, contractAddress, index) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;

  const nftContract = etherspotInstance.registerContract('nftContract', nftAbi, contractAddress);

  return nftContract.callTokenOfOwnerByIndex(accountAddress, index).then(val => val.toNumber());
}

export const getNftBalanceAndTokenId = async (chain, contractAddress) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;

  const nftContract = etherspotInstance.registerContract('nftContract', nftAbi, contractAddress);

  const balance = await nftContract.callBalanceOf(accountAddress).then(val => val.toNumber());
  let tokenId;

  if (balance) {
    tokenId = await nftContract.callTokenOfOwnerByIndex(accountAddress, 0).then(val => val.toNumber());
  }

  return {
    balance,
    tokenId,
  };
}

export const getNftBalance = async (chain, contractAddress) => {
  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;

  const nftContract = etherspotInstance.registerContract('nftContract', nftAbi, contractAddress);

  return nftContract.callBalanceOf(accountAddress).then(val => val.toNumber());
}

export const connectCollabBot = async () => {
  const etherspotInstance = getEtherspotChainInstance(CHAIN.AURORA_TESTNET);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;
  const { items: accounts } = await etherspotInstance.getAccountMembers({
    account: accountAddress,
  });

  const isBotAdded = accounts.some(account => {
    return isCaseInsensitiveMatch(account.member.address, COLLAB_BOT_ADDRESS);
  });

  if (isBotAdded) {
    console.error('Bot is already added');
    return;
  }

  await etherspotInstance.batchAddAccountOwner({
    owner: COLLAB_BOT_ADDRESS,
  });

  await etherspotInstance.estimateGatewayBatch();
  await etherspotInstance.submitGatewayBatch();
}

export const disconnectCollabBot = async () => {
  const etherspotInstance = getEtherspotChainInstance(CHAIN.AURORA_TESTNET);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;
  const { items: accounts } = await etherspotInstance.getAccountMembers({
    account: accountAddress,
  });

  const isBotAdded = accounts.some(account => {
    return isCaseInsensitiveMatch(account.member.address, COLLAB_BOT_ADDRESS);
  });

  if (!isBotAdded) return;

  await etherspotInstance.batchRemoveAccountOwner({
    owner: COLLAB_BOT_ADDRESS,
  });

  await etherspotInstance.estimateGatewayBatch();
  await etherspotInstance.submitGatewayBatch();
}

export const isCollabBotConnected = async () => {
  const etherspotInstance = getEtherspotChainInstance(CHAIN.AURORA_TESTNET);
  if (!etherspotInstance) throw new Error('Failed to get SDK instance');

  const { accountAddress } = etherspotInstance.state;
  const { items: accounts } = await etherspotInstance.getAccountMembers({
    account: accountAddress,
  });
  console.log('accounts', accounts);

  // is bot added
  return accounts.some(account => {
    return account.state === AccountMemberStates.Added
      && isCaseInsensitiveMatch(account.member.address, COLLAB_BOT_ADDRESS);
  });
}

export const isEtherspotAccountDeployed = (chain) => {
  // always deployed on aurora
  if ([CHAIN.AURORA_TESTNET, CHAIN.AURORA].includes(chain)) return true;

  const etherspotInstance = getEtherspotChainInstance(chain);
  if (!etherspotInstance) return false;

  return etherspotInstance.state.account.state === AccountStates.Deployed;
}
