import styled from 'styled-components'
import { ethers } from 'ethers'
import { useCallback, useEffect, useState } from 'react'


// components
import { ChainSelect } from './ChainSelect'
import { AssetSelect } from './AssetSelect'

// services
import { chainToNativeAsset } from '../services/wallet'

// utils
import { color } from '../utils/theme';
import {
  buildTransactions,
  formatNumber,
  isCaseInsensitiveMatch,
  isValidAddress,
} from '../utils/common'

// hooks
import { useWallet } from '../providers/WalletProvider'


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SendButton = styled.div`
  margin-top: 20px;
  background: ${color.linearRed};
  color: #fff;
  text-align: center;
  border-radius: 15px;
  margin-left: 10px;
  height: 30px;
  padding: 8px 35px;
  line-height: 30px;
  cursor: pointer;

  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  ${({ disabled }) => !disabled && `
    &:hover {
      opacity: 0.5;
    }
  `}
`;

const RecipientWrapper = styled.div`
  margin-bottom: 30px;
`;

const AddressInputWrapper = styled.div`
  border: 3px solid ${color.orange};
  padding: 5px 10px;
  display: flex;
  height: 30px;
  border-radius: 15px;
  width: 300px;
  margin-bottom: 8px;
`;

const AddressInput = styled.input`
  border: none;
  flex: 1;
  font-size: 18px;
  text-align: right;
`;

const AssetWrapper = styled.div`
  margin-bottom: 35px;
`;

const AssetInputWrapper = styled.div`
  border: 3px solid ${color.orange};
  padding: 5px 10px;
  display: flex;
  height: 30px;
  border-radius: 15px;
  width: 300px;
  margin: 4px 0 8px 0;
`;

const AssetValueInput = styled.input`
  border: none;
  flex: 1;
  font-size: 18px;
  text-align: right;
`;

const AssetBalance = styled.div`
  text-align: right;
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 14px;
`;

const ErrorMessage = styled.div`
  color: #ff0000;
  font-size: 14px;
  ${({ $marginTop }) => $marginTop && `margin-top: ${$marginTop}px;`}
`;

const Fee = styled.div`
  margin-top: 30px;
  font-size: 14px;
  font-weight: 700;
`;

const TransactionSent = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const ViewTransaction = styled.div`
  font-size: 14px;
  margin-top: 15px;
  cursor: pointer;
  background: ${color.linearRed};
  color: #fff;
  padding: 10px 20px;
  border-radius: 15px;

  &:hover {
    opacity: 0.5;
  }
`;

const CloseTransactionSent = styled.div`
  font-size: 12px;
  margin-top: 12px;
  cursor: pointer;
  font-weight: 700;

  &:hover {
    opacity: 0.5;
  }
`;

const numberInProgressRegex = /^\d*\.?\d*$/;

const normalizeAmount = (amount) => {
  let result = amount.replace(/,/g, '.').replace(/\s/g, '');

  // Trip leading and trailing spaces
  result = result.trim();

  // Trim leading zeros, but allow '0' & '0.'
  if (result.startsWith('0')) {
    result = result.replace(/^0+/, '');
    if (result === '') {
      result = '0';
    }
  }

  // Ensure 0 before decimal point
  if (result.startsWith('.')) {
    result = `0${result}`;
  }

  return result;
}

export const Send = () => {
  const {
    chain,
    setChain,
    balances,
    transactionsEstimate,
    transactionsErrorMessage,
    estimateTransactions,
    sendTransactions,
    isSendingTransaction,
    sentTransactions,
    resetSentTransactions,
    viewTransaction,
  } = useWallet();
  const [asset, setAsset] = useState(chainToNativeAsset[chain]);
  const [amount, setAmount] = useState('');
  const [addressOrEnsName, setAddressOrEnsName] = useState('');

  useEffect(() => {
    setAsset(chainToNativeAsset[chain]);
    setAmount('');
  }, [chain]);

  const onChainSelected = (newChain) => {
    setChain(newChain);
  };

  const onAssetSelected = (newAsset) => {
    setAsset(newAsset);
  };

  const onAmountChange = (el) => {
    const normalizedAmount = normalizeAmount(el?.target?.value ?? '')
    setAmount(normalizedAmount);
  }

  const onAddressOrEnsChange = (el) => {
    setAddressOrEnsName(el?.target?.value ?? '');
  }

  const { balance: balanceBN } = balances?.find((balance) => isCaseInsensitiveMatch(balance.assetAddress, asset.address)) ?? {};
  const balance = balanceBN ? ethers.utils.formatUnits(balanceBN, asset.decimals) : 0;

  const isAmountPresent = !!amount && Number(amount) > 0
  const validAmount = numberInProgressRegex.test(amount);
  const validAddressOrEnsName = isValidAddress(addressOrEnsName);

  const isAmountAboveBalance = isAmountPresent && validAmount
    ? ethers.utils.parseUnits(amount, asset.decimals).gt(balanceBN ?? 0)
    : false;

  const sendFieldsValid = isAmountPresent && validAmount && validAddressOrEnsName && !isAmountAboveBalance;

  const debouncedEstimateTransactions = useCallback(async () => {
    if (!sendFieldsValid) return;
    const transactions = buildTransactions(chain, asset, addressOrEnsName, amount);
    estimateTransactions(chain, transactions);
  }, [estimateTransactions, amount, addressOrEnsName, asset, sendFieldsValid, chain]);

  const onSendClick = () => {
    if (isSendingTransaction || isSubmitDisabled) return;
    const transactions = buildTransactions(chain, asset, addressOrEnsName, amount);
    sendTransactions(chain, transactions);
  };

  useEffect(() => {
    debouncedEstimateTransactions();
  }, [debouncedEstimateTransactions]);


  useEffect(() => {
    if (!sentTransactions) return;
    setAmount('');
    setAddressOrEnsName('');
  }, [sentTransactions, resetSentTransactions]);

  const isSubmitDisabled = !sendFieldsValid || !!transactionsErrorMessage || !transactionsEstimate || isSendingTransaction;
  const showFee = !!transactionsEstimate && !transactionsErrorMessage && sendFieldsValid;

  return (
    <Wrapper>
      <ChainSelect value={chain} onChange={onChainSelected} />
      <AssetWrapper>
        <AssetBalance>Balance: {formatNumber(balance)} {asset.symbol}</AssetBalance>
        <AssetInputWrapper>
          <AssetValueInput placeholder="0.00" onChange={onAmountChange} value={amount} />
          <AssetSelect value={asset} onChange={onAssetSelected} />
        </AssetInputWrapper>
        {!validAmount && <ErrorMessage>Invalid number</ErrorMessage>}
        {isAmountAboveBalance && <ErrorMessage>Amount above balance</ErrorMessage>}
      </AssetWrapper>
      <RecipientWrapper>
        <AddressInputWrapper>
          <AddressInput placeholder="Receiver address" onChange={onAddressOrEnsChange} value={addressOrEnsName} />
        </AddressInputWrapper>
        {(addressOrEnsName !== '' && !validAddressOrEnsName) && <ErrorMessage>Invalid address</ErrorMessage>}
      </RecipientWrapper>
      {!!transactionsErrorMessage && <ErrorMessage $marginTop={30}>{transactionsErrorMessage}</ErrorMessage>}
      {showFee && <Fee>Transaction fee: {formatNumber(ethers.utils.formatEther(transactionsEstimate))} {chainToNativeAsset[chain].symbol}</Fee>}
      {!sentTransactions && (
        <SendButton disabled={isSubmitDisabled} onClick={onSendClick}>
          {isSendingTransaction ? 'Sending...' : 'Send'}
        </SendButton>
      )}
      {!!sentTransactions && (
        <>
          <TransactionSent>Transaction sent!</TransactionSent>
          <ViewTransaction onClick={() => viewTransaction({ batchHash: sentTransactions?.batchHash })}>View transaction</ViewTransaction>
          <CloseTransactionSent onClick={() => resetSentTransactions()}>Close</CloseTransactionSent>
        </>
      )}
    </Wrapper>
  )
};
