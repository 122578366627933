import styled from 'styled-components';
import { useCallback, useEffect, useState, useRef } from 'react'

// constants
import {
  COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET,
  COLLAB_NFT_CONTRACT_ADDRESS_POLYGON_MUMBAI_TESTNET
} from '../constants/assetConstants'
import { CHAIN } from '../constants/chainConstants'
import { STORAGE_KEY } from '../constants/storageConstants'

// components
import { ChainSelect } from './ChainSelect';

// hooks
import { useWallet } from '../providers/WalletProvider';

// services
import { setItem } from '../services/storage';
import {
  getNftBalance,
  getNftBalanceAndTokenId,
  buildBurnNftTransaction,
  buildTestnetNftFromAuroraToPolygonMigrationTransactions,
  isCollabBotConnected,
  connectCollabBot,
  disconnectCollabBot,
} from '../services/etherspot';
import { requestCollabNftMigration } from '../services/collabNfts';
import { chainToChainDetails } from '../services/wallet';

// utils
import { pause } from '../utils/common';


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
`;

const Nft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  background: #8fb2ff;
  border-radius: 15px;
`;

const Button = styled.div`
  font-size: 12px;
  margin-top: 20px;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000;
 
  ${({ $disabled }) => $disabled && 'opacity: 0.5;'}
  ${({ $disabled }) => !$disabled && `
    &:hover {
      opacity: 0.5;
    }
  `}
`;

const Link = styled.a`
  display: block;
  font-size: 12px;
  margin-top: 20px;
  padding: 5px;
  color: #000;
  cursor: pointer;
  border-radius: 10px;
  border: 1px solid #000;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
`;

const BotConnection = styled.div`
  margin-top: -20px;
  margin-bottom: 20px;
  
  .connected {
    color: green;
  }

  .connect {
    color: green;
  }
  
  .link {
    font-size: 14px;

    span {
      text-decoration: none;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
`;

const collabNftAssetPerChain = {
  [CHAIN.AURORA_TESTNET]: { symbol: 'COLLAB', address: COLLAB_NFT_CONTRACT_ADDRESS_AURORA_TESTNET },
  [CHAIN.POLYGON_MUMBAI]: { symbol: 'COLLAB', address: COLLAB_NFT_CONTRACT_ADDRESS_POLYGON_MUMBAI_TESTNET },
};

export const CollabNFTS = ({ nftMintingStarted, botAutoConnectionStarted }) => {
  const { chain, setChain, sendTransactions, address: accountAddress } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [isMigrating, setIsMigrating] = useState(false);
  const [isBurning, setIsBurning] = useState(false);
  const [nftsPerChain, setNftsPerChain] = useState({});
  const [isNftMinting, setIsNftMinting] = useState(false);
  const [isBotConnected, setIsBotConnected] = useState(undefined);
  const botAutoConnectionStatus = useRef(undefined);

  const onChainSelected = (newChain) => {
    setChain(newChain);
  };

  const fetchNfts = useCallback(async () => {
    setIsLoading(true);

    const { balance, tokenId } = await getNftBalanceAndTokenId(chain, collabNftAssetPerChain[chain].address);

    if (!balance) {
      setIsLoading(false);
      setNftsPerChain((current) => ({ ...current, [chain]: [] }));
      return;
    }

    setIsLoading(false);
    setNftsPerChain((current) => ({
      ...current,
      [chain]: [{ tokenId, tokenAddress: collabNftAssetPerChain[chain].address }],
    }));
  }, [chain]);

  const onMigrateClick = async (tokenId) => {
    if (isMigrating) return;

    setIsMigrating(true);
    const migrationTransactions = await buildTestnetNftFromAuroraToPolygonMigrationTransactions(tokenId);
    await sendTransactions(CHAIN.AURORA_TESTNET, migrationTransactions);
    await pause(3);
    await requestCollabNftMigration(accountAddress);

    setNftsPerChain((current) => ({ ...current, [CHAIN.AURORA_TESTNET]: [] }));
    setIsMigrating(false);
    alert('Successfully migrated!')

    onChainSelected(CHAIN.POLYGON_MUMBAI);
  };

  const smartReloadNfts = useCallback(async (nonZeroValue, cb) => {
    for (const i of [1, 2, 3, 3, 3, 3, 3]) {
      await pause(i);
      const balance = await getNftBalance(chain, collabNftAssetPerChain[chain].address);
      if ((balance && nonZeroValue) || (!balance && !nonZeroValue)) {
        cb();
        fetchNfts();
        break;
      }
    }
  }, [chain, fetchNfts]);

  const onBurnClick = async (chain, tokenId) => {
    if (isBurning) return;

    setIsBurning(true);
    const migrationTransaction = await buildBurnNftTransaction(chain, tokenId);
    await sendTransactions(chain, [migrationTransaction]);

    if (chain === CHAIN.AURORA_TESTNET) {
      setItem(STORAGE_KEY.MINTED_COLLAB_NFT_TOKEN_ID, '');
    }

    smartReloadNfts(false, () => {
      setIsBurning(false);
    });
    alert('Burn transaction submitted');
  };

  useEffect(() => {
    fetchNfts();
  }, [chain, fetchNfts]);

  useEffect(() => {
    if (nftMintingStarted) {
      setIsNftMinting(true);
      smartReloadNfts(true, () => {
        setIsNftMinting(false);
      });
    }
  }, [nftMintingStarted, smartReloadNfts]);

  useEffect(() => {
    if (!botAutoConnectionStarted && botAutoConnectionStatus.current) {
      isCollabBotConnected().then(setIsBotConnected);
    }
    botAutoConnectionStatus.current = botAutoConnectionStarted;
  }, [botAutoConnectionStarted]);

  useEffect(() => {
    isCollabBotConnected().then(setIsBotConnected);
  }, []);

  const chainNfts = nftsPerChain[chain] ?? [];
  const mumbaiExplorerAddressHistory = chainToChainDetails[CHAIN.POLYGON_MUMBAI].historyExplorerUrl;

  return (
    <Wrapper>
      {isBotConnected !== undefined && !botAutoConnectionStarted && (
        <BotConnection>
          {isBotConnected && (
            <>
              <span className={'connected'}>Bot connected</span>&nbsp;
              <span className="link">(<span onClick={disconnectCollabBot}>disconnect</span>)</span>
            </>
          )}
          {!isBotConnected && (
            <>
              <span className={'notConnected'}>Bot is not connected</span>&nbsp;
              <span className="link connect">(<span onClick={connectCollabBot}>connect</span>)</span>
            </>
          )}
        </BotConnection>
      )}
      {botAutoConnectionStarted && <BotConnection>Connecting Collab.Land bot..</BotConnection>}
      <ChainSelect value={chain} chains={[CHAIN.AURORA_TESTNET, CHAIN.POLYGON_MUMBAI]} onChange={onChainSelected} />
      {isLoading && !isNftMinting && <span>Loading...</span>}
      {isNftMinting && <span>Minting your NFT...</span>}
      {!isLoading && !isNftMinting && !chainNfts.length && <span>No NFTs</span>}
      {!isLoading && !!chainNfts.length && (
        <ListWrapper>
          {chainNfts.map(({ tokenAddress, tokenId }, index) => (
            <Nft key={`${chain}-${tokenAddress}-${tokenId}`}>
              <strong>Collab NFT #{tokenId}</strong>
              {chain === CHAIN.AURORA_TESTNET && (
                <>
                  <Button
                    onClick={() => onMigrateClick(tokenId)}
                    $disabled={isMigrating}
                  >
                    {isMigrating ? 'Transferring...' : 'Transfer to OpenSea (testnet)'}
                  </Button>
                  <Button
                    onClick={() => onBurnClick(chain, tokenId)}
                    $disabled={isBurning}
                  >
                    {isBurning ? 'Burning...' : 'Burn'}
                  </Button>
                </>
              )}
              {chain === CHAIN.POLYGON_MUMBAI && (
                <>
                  <Link href={`${mumbaiExplorerAddressHistory}/${accountAddress}#tokentxnsErc721`}
                     target="_blank">View on Explorer</Link>

                  <Link href={`https://testnets.opensea.io/assets/mumbai/${tokenAddress}/${tokenId}`}
                     target="_blank">View on OpenSea</Link>
                </>
              )}
            </Nft>
          ))}
        </ListWrapper>
      )}
    </Wrapper>
  )
};
