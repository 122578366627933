export const fontWeight = {
  regular: 400,
  bold: 700,
};

export const fontFamily = {
  default: 'Lato',
};

export const color = {
  red: '#f43b40',
  orange: '#f76e30',
  linearRed: 'linear-gradient(90deg, #f43b40, #f76e30)',
};
