import axios from 'axios';

export const mintCollabNftToAddress = async (address) => {
  try {
   const { data } = await axios.post(
     'https://collab-land.herokuapp.com/aurora_mint',
     { address },
     { headers: {
        'Content-Type': 'application/json',
       }
     }
   );

   return data?.tokenId;
  } catch (e) {
    //
  }

  return null;
}

export const requestCollabNftMigration = async (address) => {
  try {
   const { data } = await axios.post(
     'https://collab-land.herokuapp.com/polygon_mint',
     { address },
     { headers: {
        'Content-Type': 'application/json',
       }
     }
   );

   return data;
  } catch (e) {
    //
  }

  return null;
}

export const registerCollabUser = async (address, collabId) => {
  try {
   const { data } = await axios.post(
     'https://api-qa.collab.land/etherspot/user-accounts',
     { address },
     { headers: {
        'Content-Type': 'application/json',
         'Authorization': `AE ${collabId}`,
       }
     }
   );

   return data;
  } catch (e) {
    console.log(e);
  }

  return null;
}
