import styled from 'styled-components';
import QRCode from "react-qr-code";

// hooks

// utils
import { color } from '../utils/theme'
import { useWallet } from '../providers/WalletProvider'


const Wrapper = styled.div`
`;

const StyleQrCode = styled(QRCode)`
  border: 3px solid ${color.orange};
  padding: 15px;
  border-radius: 10px;
`;

const Address = styled.div`
  margin-top: 15px;
`;

export const Receive = () => {
  const { address } = useWallet();

  return (
    <Wrapper>
      <StyleQrCode value={address} />
      <Address>{address}</Address>
    </Wrapper>
  )
};
