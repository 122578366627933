import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components';
import { useParams } from 'react-router-dom';

// constants
import { STORAGE_KEY } from '../constants/storageConstants'

// components
import { Send } from './Send';
import { Receive } from './Receive';
import { History } from './History';
import { CollabNFTS } from './CollabNfts';

// hooks
import { useWallet } from '../providers/WalletProvider';
import { useWalletConnect } from '../providers/WalletConnectProvider';

// services
import { mintCollabNftToAddress, registerCollabUser } from '../services/collabNfts';
import { getItem, setItem } from '../services/storage';
import { connectCollabBot } from '../services/etherspot';

// utils
import { color, fontWeight } from '../utils/theme';


const Wrapper = styled.div`
  max-width: 600px;
  padding: 0 15px;
  margin: 50px auto 0;
  text-align: center;
`;

const TabsContent = styled.div`
  max-width: 600px;
  padding: 0 15px;
  margin: 50px auto 0;
  text-align: center;
`;

const TabsButton = styled.div`
  ${({ $active }) => $active && `text-decoration: underline;`}
  margin: 0 15px;
  cursor: pointer;
  font-weight: ${fontWeight.bold};

  &:hover {
    text-decoration: underline;
  }
`;

const TabsNavigation = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConnectWalletButton = styled.div`
  background: ${color.linearRed};
  color: #fff;
  text-align: center;
  border-radius: 10px;
  height: 25px;
  padding: 15px;
  font-size: 20px;
  line-height: 25px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const ErrorMessage = styled.div`
  font-size: 14px;
  margin-top: 15px;
  color: ${color.red};
`;

const TAB = {
  RECEIVE: 'RECEIVE',
  SEND: 'SEND',
  HISTORY: 'HISTORY',
  COLLAB_NFTS: 'COLLAB_NFTS',
};

export const Wallet = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  const { collabId } = urlParams;
  const { referralCode } = useParams();
  const [activeTab, setActiveTab] = useState(referralCode ? TAB.COLLAB_NFTS : TAB.RECEIVE);
  const [nftMintingStarted, setNftMintingStarted] = useState(false);
  const [botAutoConnectionStarted, setBotAutoConnectionStarted] = useState(false);
  const { scan } = useWalletConnect();

  const {
    address,
    isConnected,
    connect,
    isConnecting,
    connectionError,
  } = useWallet();

  const processReferralCode = useCallback(async () => {
    if (!address) return;

    // connect collab bot
    if (collabId && !getItem(STORAGE_KEY.ADDED_COLLAB_BOT)) {
      try {
        setBotAutoConnectionStarted(true);
        await connectCollabBot();
        await registerCollabUser(address, collabId);
      } catch (e) {
        console.log(e);
      } finally {
        setBotAutoConnectionStarted(false);
        setItem(STORAGE_KEY.ADDED_COLLAB_BOT, true);
      }
    }

    // mint item
    if (getItem(STORAGE_KEY.MINTED_COLLAB_NFT_TOKEN_ID)) return;
    setNftMintingStarted(true);
    const tokenId = await mintCollabNftToAddress(address);
    if (!tokenId) return;

    setItem(STORAGE_KEY.MINTED_COLLAB_NFT_TOKEN_ID, tokenId);
    setNftMintingStarted(false);
  }, [address, collabId]);

  useEffect(() => {
    if (!isConnected || !referralCode) return;
    if (referralCode === 'lisbon2021') processReferralCode();
  }, [isConnected, referralCode, processReferralCode]);

  if (!isConnected) {
    return (
      <Wrapper>
        {isConnecting && <span>Connecting...</span>}
        {!isConnecting && (<ConnectWalletButton onClick={() => connect()}>Connect Your Wallet</ConnectWalletButton>)}
        {!!connectionError && (<ErrorMessage>{connectionError}</ErrorMessage>)}
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TabsNavigation>
        <TabsButton $active={activeTab === TAB.RECEIVE} onClick={() => setActiveTab(TAB.RECEIVE)}>My account</TabsButton>
        <TabsButton $active={activeTab === TAB.SEND} onClick={() => setActiveTab(TAB.SEND)}>Send</TabsButton>
        <TabsButton $active={activeTab === TAB.HISTORY} onClick={() => setActiveTab(TAB.HISTORY)}>History</TabsButton>
        <TabsButton $active={activeTab === TAB.COLLAB_NFTS} onClick={() => setActiveTab(TAB.COLLAB_NFTS)}>Collab NFTs</TabsButton>
        <TabsButton onClick={scan}>Use WalletConnect</TabsButton>
      </TabsNavigation>
      <TabsContent>
        {activeTab === TAB.RECEIVE && <Receive address={address} /> }
        {activeTab === TAB.SEND && <Send address={address} /> }
        {activeTab === TAB.HISTORY && <History />}
        {activeTab === TAB.COLLAB_NFTS && (
          <CollabNFTS nftMintingStarted={nftMintingStarted} botAutoConnectionStarted={botAutoConnectionStarted} />
        )}
      </TabsContent>
    </Wrapper>
  )
}
