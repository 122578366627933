import styled from 'styled-components';

// services
import { chainToChainDetails, getSupportedChains } from '../services/wallet'


const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 50px;
`;

const Network = styled.div`
  display: flex;
  align-items: center;
  margin-right: 35px;
  ${({ $isCurrent }) => $isCurrent && `border-bottom: 1px solid #000;`}
  ${({ $isCurrent }) => !$isCurrent && `cursor: pointer; &:hover { border-bottom: 1px solid #000; }`}
`;

const ChainIcon = styled.img`
  height: 20px;
  margin-right: 5px;
`;

export const ChainSelect = ({ onChange, chains, value: currentChain }) => (
  <Wrapper>
    {(chains ?? getSupportedChains()).map((chain) => (
      <Network $isCurrent={currentChain === chain} key={chain} onClick={() => onChange(chain)}>
        <ChainIcon src={chainToChainDetails[chain].iconUrl} />
        {chainToChainDetails[chain].title}
      </Network>
    ))}
  </Wrapper>
);
