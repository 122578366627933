import { utils } from 'ethers';
import { isHexString } from '@walletconnect/utils';

// constants
import { CHAIN } from '../constants/chainConstants'
import { ADDRESS_ZERO } from '../constants/assetConstants'

// services
import {
  createEtherspotInstance,
  etherspotSessionExists,
  getEtherspotAccountAddress,
  getEtherspotAsset,
  getEtherspotAssets,
  getEtherspotBalances,
  getTorusWalletAddress,
  getTorusWalletProvider,
  isEtherspotAccountDeployed,
  signEtherspotTransaction,
  signMessageWithEtherspotProvider,
} from './etherspot'

// assets
import auroraImage from '../assets/aurora.png';


export const chainToNativeAsset = {
  [CHAIN.ETHEREUM_MAINNET]: {
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: 'https://tokens.1inch.exchange/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
  },
  [CHAIN.ETHEREUM_KOVAN]: {
    name: 'ETH (Kovan)',
    symbol: 'ETH',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: 'https://tokens.1inch.exchange/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png',
  },
  [CHAIN.POLYGON]: {
    name: 'Matic',
    symbol: 'MATIC',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: 'https://tokens.1inch.exchange/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
  },
  [CHAIN.POLYGON_MUMBAI]: {
    name: 'Matic (Mumbai)',
    symbol: 'MATIC',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: 'https://tokens.1inch.exchange/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png',
  },
  [CHAIN.AURORA]: {
    name: 'Aurora ETH',
    symbol: 'aETH',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: auroraImage,
  },
  [CHAIN.AURORA_TESTNET]: {
    name: 'Aurora ETH (testnet)',
    symbol: 'aETH',
    decimals: 18,
    address: ADDRESS_ZERO,
    iconUrl: auroraImage,
  },
};

export const chainToChainId = {
  [CHAIN.ETHEREUM_KOVAN]: 32,
  [CHAIN.ETHEREUM_MAINNET]: 1,
  [CHAIN.POLYGON]: 137,
  [CHAIN.POLYGON_MUMBAI]: 80001,
  [CHAIN.AURORA]: 1313161554,
  [CHAIN.AURORA_TESTNET]: 1313161555,
};

export const chainIdToChain = {
  32: CHAIN.ETHEREUM_KOVAN,
  1: CHAIN.ETHEREUM_MAINNET,
  137: CHAIN.POLYGON,
  80001: CHAIN.POLYGON_MUMBAI,
  1313161554: CHAIN.AURORA,
  1313161555: CHAIN.AURORA_TESTNET,
};

export const chainToChainDetails = {
  [CHAIN.ETHEREUM_KOVAN]: {
    title: 'Kovan (testnet)',
    chainId: chainToChainId[CHAIN.ETHEREUM_KOVAN],
    rpcUrl: 'https://kovan.infura.io/v3/31a9622172b644d8844cbff3fc17eefd',
    iconUrl: chainToNativeAsset[CHAIN.ETHEREUM_KOVAN].iconUrl,
    explorerUrl: 'https://kovan.etherscan.io/',
    historyExplorerUrl: 'https://kovan.etherscan.io/address',
    transactionExplorerUrl: 'https://kovan.etherscan.io/tx',
  },
  [CHAIN.ETHEREUM_MAINNET]: {
    title: 'Ethereum',
    rpcUrl: 'https://homestead.infura.io/v3/31a9622172b644d8844cbff3fc17eefd',
    iconUrl: chainToNativeAsset[CHAIN.ETHEREUM_MAINNET].iconUrl,
    explorerUrl: 'https://etherscan.io/',
    historyExplorerUrl: 'https://etherscan.io/address',
    transactionExplorerUrl: 'https://etherscan.io/tx',
  },
  [CHAIN.POLYGON]: {
    title: 'Polygon',
    chainId: chainToChainId[CHAIN.POLYGON],
    rpcUrl: 'https://polygon-rpc.com/',
    iconUrl: chainToNativeAsset[CHAIN.POLYGON].iconUrl,
    explorerUrl: 'https://polygonscan.com/',
    historyExplorerUrl: 'https://polygonscan.com/address',
    transactionExplorerUrl: 'https://polygonscan.com/tx',
  },
  [CHAIN.POLYGON_MUMBAI]: {
    title: 'Mumbai (testnet)',
    chainId: chainToChainId[CHAIN.POLYGON_MUMBAI],
    rpcUrl: 'https://polygon-mumbai.infura.io/v3/31a9622172b644d8844cbff3fc17eefd',
    iconUrl: chainToNativeAsset[CHAIN.POLYGON].iconUrl,
    explorerUrl: 'https://mumbai.polygonscan.com/',
    historyExplorerUrl: 'https://mumbai.polygonscan.com/address',
    transactionExplorerUrl: 'https://mumbai.polygonscan.com/tx',
  },
  [CHAIN.AURORA]: {
    title: 'Aurora',
    chainId: chainToChainId[CHAIN.AURORA],
    rpcUrl: 'https://mainnet.aurora.dev/',
    iconUrl: chainToNativeAsset[CHAIN.AURORA].iconUrl,
    explorerUrl: null,
    historyExplorerUrl: null,
    transactionExplorerUrl: null,
  },
  [CHAIN.AURORA_TESTNET]: {
    title: 'Aurora (testnet)',
    chainId: chainToChainId[CHAIN.AURORA_TESTNET],
    rpcUrl: 'https://testnet.aurora.dev/',
    iconUrl: chainToNativeAsset[CHAIN.AURORA_TESTNET].iconUrl,
    explorerUrl: 'https://explorer.testnet.aurora.dev/',
    historyExplorerUrl: 'https://explorer.testnet.aurora.dev/address',
    transactionExplorerUrl: 'https://explorer.testnet.aurora.dev/tx',
  },
};

export const connectWallet = async (chain, restoreSession) => {
  const sessionExists = etherspotSessionExists();
  if (restoreSession && !sessionExists) return;

  await createEtherspotInstance(chain);
};

export const getWalletAddress = (chain) => getEtherspotAccountAddress(chain);

export const getSupportedAssets = (chain) => getEtherspotAssets(chain);

export const getSupportedAsset = (chain, assetAddress) => getEtherspotAsset(chain, assetAddress);

export const getBalances = async (chain, assets) => getEtherspotBalances(chain, assets);

export const signTransaction = async (chain, transaction) => signEtherspotTransaction(chain, transaction);

export const signMessage = (message) => {
  const messageBytes = isHexString(message)
    ? utils.toUtf8String(message)
    : message;

  return signMessageWithEtherspotProvider(messageBytes);
}

export const signTypedData = async (typedData) => new Promise((
  resolve,
  reject,
) => {
  /**
   * Note: won't work if typed data domain chain ID is different from connected Torus provider,
   * this is native protection to not sign something on other chain
   */
  const from = getTorusWalletAddress();
  const provider = getTorusWalletProvider();

  provider.sendAsync({
    from,
    params: [from, typedData],
    method: 'eth_signTypedData_v4',
  }, (error, result) => {
    if (error || result.error) {
      reject(error?.message ?? result?.error?.message ?? 'Unknown Error');
      return;
    }

    resolve(result.result);
  })
});


export const canSignWithWallet = (chain) => isEtherspotAccountDeployed(chain);

export const getSupportedChains = () => [
  CHAIN.AURORA_TESTNET,
  CHAIN.AURORA,
  CHAIN.POLYGON,
  CHAIN.POLYGON_MUMBAI,
  CHAIN.ETHEREUM_MAINNET,
];
