import React, { useCallback, useEffect, useMemo, useState } from 'react'
import WalletConnect from "@walletconnect/client";
import QrReader from 'react-camera-qr';
import styled from 'styled-components';
import { ethers } from 'ethers';

// components
import { Modal } from '../components/Modal';

// services
import { getItem, setItem } from '../services/storage';
import {
  canSignWithWallet,
  chainIdToChain,
  chainToNativeAsset,
  signMessage,
  signTransaction,
  signTypedData,
} from '../services/wallet';

// utils
import { color } from '../utils/theme';
import { parseCallRequest } from '../utils/walletConnect';
import { formatNumber } from '../utils/common';

// hooks
import { useWallet } from './WalletProvider';


const ModalTitle = styled.p`
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
  font-size: 20px;
`;

const CallRequestDetailsRow = styled.p`
  margin-bottom: 15px;
  word-break: break-all;
`;

const CallRequestConfirm = styled.div`
  margin-top: 30px;
  background: ${color.linearRed};
  color: #fff;
  text-align: center;
  border-radius: 15px;
  height: 30px;
  padding: 8px 35px;
  line-height: 30px;
  cursor: pointer;

  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  ${({ disabled }) => !disabled && `
    &:hover {
      opacity: 0.5;
    }
  `}
`;

const CallRequestReject = styled.div`
  margin: 10px 0px 20px;
  color: ${color.linearRed};
  text-align: center;
  cursor: pointer;

  ${({ disabled }) => disabled && 'opacity: 0.5;'}
  ${({ disabled }) => !disabled && `
    &:hover {
      opacity: 0.5;
    }
  `}
`;

const DeploymentWarning = styled.p`
  margin: 30px 0;
  text-align: center;
  text-decoration: underline;
`;

export const WalletConnectContext = React.createContext(null);

const walletConnectStorageService = ({
  getSessions: () => {
    let sessions = [];

    try {
      const sessionsRaw = getItem(`walletConnectSessions`)
      sessions = sessionsRaw ? JSON.parse(sessionsRaw) : [];
    } catch (err) {
      //
    }

    return sessions
  },

  setSessions: (sessions) => {
    setItem(`walletConnectSessions`, JSON.stringify(sessions));
  },
});

const clientMeta = {
  name: 'Pillar Web Wallet',
  description: 'Pillar Web Wallet',
  url: 'https://pillar-web-wallet-mvp.netlify.app/',
  icons: ['https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/8c/36/c7/8c36c7d5-0698-97b5-13b2-a51564706cf5/AppIcon-1x_U007emarketing-85-220-0-6.png/460x0w.jpg'],
};

let activeConnectors = [];

export const WalletConnectProvider = ({ children }) => {
  const [qrScannerVisible, setQrScannerVisible] = useState(false);
  const [pendingConnector, setPendingConnector] = useState(null);
  const [pendingCallRequest, setPendingCallRequest] = useState(null);
  const {
    address,
    estimateTransactions,
    transactionsEstimate,
    sendTransactions,
    isSendingTransaction,
  } = useWallet();

  const setConnectorListeners = useCallback(async (connector) => {
    connector.on('call_request', async (error, payload) => {
      if (error || !payload) {
        alert('WalletConnect request failed!');
        return;
      }

      const chain = chainIdToChain[connector.chainId];
      const parsed = await parseCallRequest(chain, payload);
      if (parsed.transaction) {
        estimateTransactions(chain, [parsed.transaction]);
      }

      setPendingCallRequest({ chain, peerId: connector.peerId, ...payload, ...parsed });
    });

    connector.on('disconnect', () => {
      activeConnectors = activeConnectors.filter(({ peerId }) => peerId !== connector.peerId);
      walletConnectStorageService.setSessions(activeConnectors.map(({ session }) => session));
    });
  }, [estimateTransactions]);

  useEffect(() => {
    const storedSessions = walletConnectStorageService.getSessions();

    activeConnectors = storedSessions.map((session) => {
      const connector = new WalletConnect({ session, clientMeta });
      setConnectorListeners(connector);
      return connector;
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!pendingConnector || !address) return;

    pendingConnector.on('session_request', (error, payload) => {
      if (error) {
        alert('Failed to connect!');
        return;
      }

      const { peerId, peerMeta, chainId } = payload?.params?.[0] || {};
      if (!peerId || !peerMeta) {
        alert('Failed to connect, peer data is missing!');
        return;
      }

      const sessionData = {
        accounts: [address],
        chainId: chainId ?? 1,
      };

      try {
        pendingConnector.approveSession(sessionData);

        const establishedConnector = new WalletConnect({ session: pendingConnector.session, clientMeta });
        setPendingConnector(null);

        const activeConnectorsWithoutMatchingPeerId = activeConnectors.filter(({ peerId }) => peerId !== establishedConnector.peerId);

        setConnectorListeners(establishedConnector);
        activeConnectors = [...activeConnectorsWithoutMatchingPeerId, establishedConnector];

        walletConnectStorageService.setSessions(activeConnectors.map(({ session }) => session));
      } catch (approveSessionError) {
        alert('Failed to establish session!');
      }
    });
  }, [pendingConnector, address]); // eslint-disable-line


  const handleScannerError = () => {
    setQrScannerVisible(false);
    alert('Scanner aborted!');
  }

  const handleScannerResult = (result) => {
    if (!result) return;

    if (!result.startsWith('wc:')) {
      alert('Failed to connect, wrong QR code!');
      return;
    }

    setQrScannerVisible(false);

    const connector = new WalletConnect({
      uri: result,
      clientMeta: {
        name: 'Pillar Web Wallet',
        description: 'Pillar Web Wallet',
        url: 'https://pillar-web-wallet-mvp.netlify.app/',
        icons: ['https://is3-ssl.mzstatic.com/image/thumb/Purple113/v4/8c/36/c7/8c36c7d5-0698-97b5-13b2-a51564706cf5/AppIcon-1x_U007emarketing-85-220-0-6.png/460x0w.jpg'],
      },
    });

    setPendingConnector(connector);
  };

  const scan = () => {
    // hack into WalletConnect lib to remove active connector that forbids multiple connections
    localStorage.removeItem('walletconnect');
    setQrScannerVisible(true);
  }

  const rejectPendingCallRequest = useCallback(() => {
    if (!pendingCallRequest || isSendingTransaction) return;

    const { peerId, id: callId } = pendingCallRequest;
    setPendingCallRequest(null);

    const matchingConnector = activeConnectors.find((activeConnector) => activeConnector.peerId === peerId);
    if (!matchingConnector) {
      return;
    }

    matchingConnector.rejectRequest({
      id: +callId,
      error: new Error('Call request rejected by user.'),
    });
  }, [isSendingTransaction, pendingCallRequest]);

  const isTransaction = useMemo(
    () => pendingCallRequest && pendingCallRequest.method === 'eth_sendTransaction',
    [pendingCallRequest],
  );

  const isSubmitDisabled = useMemo(() => {
    if (!pendingCallRequest) return true;

    if (!isTransaction) return !canSignWithWallet(pendingCallRequest.chain);

    return !transactionsEstimate || isSendingTransaction;
  } , [transactionsEstimate, isSendingTransaction, pendingCallRequest, isTransaction]);


  const approvePendingCallRequest = useCallback(async () => {
    if (!pendingCallRequest || isSubmitDisabled) return;

    const { peerId, id: callId, method, message, transaction, chain } = pendingCallRequest;
    setPendingCallRequest(null);

    const matchingConnector = activeConnectors.find((activeConnector) => activeConnector.peerId === peerId);
    if (!matchingConnector) {
      return;
    }

    let result;
    try {
      if (method === 'eth_sendTransaction') {
        ({ hash: result } = await sendTransactions(chain, [transaction], true));
      } else if (method === 'eth_signTransaction') {
        result = await signTransaction(chain, transaction);
      } else if (method.includes('signTypedData')) {
        result = await signTypedData(message);
      } else {
        result = await signMessage(message);
      }
    } catch (error) {
      //
    }

    if (!result) {
      alert('Failed to confirm.');
      matchingConnector.rejectRequest({
        id: +callId,
        error: new Error('Failed to sign a message.'),
      });
      return;
    }

    matchingConnector.approveRequest({
      id: +callId,
      result,
    });
  }, [isSubmitDisabled, pendingCallRequest, sendTransactions]);

  const providerData = useMemo(() => ({
    scan
  }), []);

  return (
    <WalletConnectContext.Provider value={{ providerData }}>
      <Modal
        isVisible={qrScannerVisible}
        onClose={() => setQrScannerVisible(false)}
      >
        <ModalTitle>Scan QR code from your OpenSea connection page</ModalTitle>
        <QrReader
          delay={300}
          onError={handleScannerError}
          onScan={handleScannerResult}
          style={{ width: '100%' }}
        />
      </Modal>
      <Modal
        isVisible={!!pendingCallRequest}
        onClose={rejectPendingCallRequest}
      >
        <ModalTitle style={{ marginBottom: 30 }}>WalletConnect Call Request</ModalTitle>
        {!!pendingCallRequest?.displayParams && (
          <>
            {pendingCallRequest.displayParams.map(({ label, value }) => (
              <CallRequestDetailsRow><strong>{label}:</strong> {value}</CallRequestDetailsRow>
            ))}
            {isTransaction && (
              <CallRequestDetailsRow>
                <strong>Transaction fee:</strong>&nbsp;
                {!!transactionsEstimate && <>{formatNumber(ethers.utils.formatEther(transactionsEstimate))} {chainToNativeAsset[pendingCallRequest.chain].symbol}</>}
                {!transactionsEstimate && `Estimating...`}
              </CallRequestDetailsRow>
            )}
          </>
        )}
        {!isTransaction && isSubmitDisabled && (
          <DeploymentWarning>
            Cannot sign with un-deployed wallet.<br/> Wallet deployment happens on any first outgoing transaction.
          </DeploymentWarning>
        )}
        <CallRequestConfirm onClick={approvePendingCallRequest} disabled={isSubmitDisabled}>
          {isSendingTransaction ? 'Sending...' : 'Confirm'}
        </CallRequestConfirm>
        <CallRequestReject onClick={rejectPendingCallRequest} disabled={isSendingTransaction}>Reject</CallRequestReject>
      </Modal>
      {children}
    </WalletConnectContext.Provider>
  )
};

export const useWalletConnect = () => React.useContext(WalletConnectContext)?.providerData ?? {}
